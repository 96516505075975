import { TLoginStepItem } from "../../resources/loginSteps";
import { BaseAction } from "../types";


export enum LoginActionTypes {
  setPhone = 'setPhone',
  setPhoneWithDash = 'setPhoneWithDash',
  setLoginStep = "setLoginStep",
  setTimer = 'setTimer',
  resetLoginData = 'resetLoginData'
}

export type TLoginInitialState = {
  phone: LoginPhonePayload;
  phoneWithDash: PhoneWithDashPayload;
  loginStep: TLoginStepItem,
  timer: TimerPayload
}

export type LoginPhonePayload = string;
export type setPhoneAction = BaseAction<LoginActionTypes, LoginPhonePayload>

export type PhoneWithDashPayload = string;
export type setPhoneWithDashAction = BaseAction<LoginActionTypes, PhoneWithDashPayload>

export type setLoginStepActionType = BaseAction<LoginActionTypes, TLoginStepItem>

export type TimerPayload = number;
export type setTimerActionType = BaseAction<LoginActionTypes, TimerPayload>

// export type resetLoginDataType = BaseAction<LoginActionTypes, null>