import React from 'react';

type TProps = {
  onClick?: () => void
}

const SvgTrash = ({onClick} : TProps) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M4 7.5H20" stroke="#919DB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 11.5V17.5" stroke="#919DB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 11.5V17.5" stroke="#919DB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M5 7.5L6 19.5C6 20.0304 6.21071 20.5391 6.58579 20.9142C6.96086 21.2893 7.46957 21.5 8 21.5H16C16.5304 21.5 17.0391 21.2893 17.4142 20.9142C17.7893 20.5391 18 20.0304 18 19.5L19 7.5"
        stroke="#919DB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M9 7.5V4.5C9 4.23478 9.10536 3.98043 9.29289 3.79289C9.48043 3.60536 9.73478 3.5 10 3.5H14C14.2652 3.5 14.5196 3.60536 14.7071 3.79289C14.8946 3.98043 15 4.23478 15 4.5V7.5"
        stroke="#919DB0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SvgTrash;