import { SummarySwitchType, SummaryWeekType } from "../../context/SummaryContext";
import { BaseAction } from "../types";


export enum SettingsActionTypes {
  setWagesSettings = 'setWagesSettings',
  setCurrentSummary = 'setCurrentSummary',
  setSummaryWeek = 'setSummaryWeek'
}

export type TSettingsInitialState = {
  settings: SettingsPayload | null,
  summary: SummarySwitchType,
  summaryWeek: SummaryWeekType
}

export type SettingsPayload = {
  material_comm_commission: number,
};
export type setSettingsAction = BaseAction<SettingsActionTypes, SettingsPayload>

export type setCurrentSummaryActionType = BaseAction<SettingsActionTypes, SummarySwitchType>

export type setSummaryWeekActionType = BaseAction<SettingsActionTypes, SummaryWeekType>