import { combineReducers } from 'redux';

import LoginReducer from './Login/login.reducer';
import SettingsReducer from "./Settings/setting.reducer";
import WagesReducer from "./Wages/wages.reducer";
import ExpensesReducer from './Expenses/expenses.reducer';

const rootReducer = combineReducers({
  login: LoginReducer,
  settings: SettingsReducer,
  wages: WagesReducer,
  expenses: ExpensesReducer
});

//This RootState is required to use useSelector later on
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;