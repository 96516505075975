import React, { ChangeEventHandler } from 'react';

interface IProps {
  label?: string,
  labelHelperText?: string
  placeholder?: string
  id?: string,
  name: string,
  type: string,
  size?: string,
  maxLength?: number,
  max?: number,
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined,
  defaultValue?: string | number | readonly string[] | undefined,
  value?: string | number | readonly string[] | undefined,
  className?: string,
  errorText?: string,
  isError?: boolean | string,
  disabled?: boolean,
  autoFocus?: boolean,
  readOnly?: boolean,
  autoComplete?: "on" | "off",
  inputMode?: "none" | "text" | 'tel' | "url" | "email" | "numeric" | 'decimal' | "search",
  ref?: any,
  required?: boolean,
  isEditedByAdmin?: boolean,
  isShowEditedLabel?: boolean,
  previousValue?: string | number
}

const CustomInput: React.FC<IProps> = ({
                                         label = '',
                                         labelHelperText = '',
                                         placeholder = '',
                                         id = '',
                                         name = '',
                                         type = 'text',
                                         size = 'sm',
                                         maxLength,
                                         max,
                                         onChange = undefined,
                                         defaultValue = '',
                                         value = undefined,
                                         className = '',
                                         isError = false,
                                         errorText = '',
                                         disabled = false,
                                         autoFocus = false,
                                         readOnly = false,
                                         autoComplete = 'off',
                                         inputMode,
                                          ref,
                                          required,
                                         isEditedByAdmin = false,
                                         isShowEditedLabel= true,
                                         previousValue = ''
                                       }) => {
  // eslint-disable-next-line eqeqeq
  const isReallyEditedByAdmin = isEditedByAdmin && previousValue != value;

  return (
    <div
      className={ `input-field ${ className } ${ size && `input-field--size-${ size }` } ` }>
      <label htmlFor={ id } className="input-field__label">
        {label}{required ? <span className="info-container__title-required">*</span> : ""}
        {labelHelperText ? (<span className={'input-field__label-helper'}>{labelHelperText}</span>): null}
      </label>
      <div className={ `input-field__item-container ${ isError && 'input-field__item-container-error' } ${isReallyEditedByAdmin ? 'input-field__item-container_edited' : ''}` }>
        { isReallyEditedByAdmin ? (
          <p className="input-field__item_old-value whitespace-nowrap">
            { previousValue }
          </p>
        ) : null }
        <input
          ref={ ref }
          id={ id }
          value={ value }
          defaultValue={ defaultValue }
          maxLength={ maxLength }
          max={ max }
          type={ type }
          name={ name }
          autoFocus={ autoFocus }
          inputMode={ inputMode }
          placeholder={ placeholder }
          onChange={ onChange }
          disabled={ disabled }
          className={ `input-field__item ${ isError && 'input-field__item-error' }` }
          autoComplete={ autoComplete }
          readOnly={ readOnly }
        />
      </div>
      {/*{ isError && errorText && <p className="form-error form-error_padding_small ">{ errorText }</p> }*/}
      {/*{ isEditedByAdmin && isShowEditedLabel ? <EditedByAdmin/> : null }*/}
    </div>

  );
};

export const InputLabel = ({ text = '', className = '', required = false }) => {
  return (
    <p className={`input-field__label ${className}`}>
      {text}
      {required ? <span className="info-container__title-required">*</span> : ""}
    </p>
  )
}

export default CustomInput;
