import React, { useContext, useEffect, useState } from 'react';
import LoaderSmall from "../LoaderSmall";
import handleClose from "../../helpers/modals/handleClosePage";
import ExpenseContext from "../../context/ExpenseContext";
import { useFormikContext } from "formik";
import { IExpensePageParams, IExpensesInitialValues } from "../../pages/expenses";
import { useTranslation } from "react-i18next";
import { customAlert, customAlertProps } from "../../helpers/modals/customAlert";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash";
import { checkIsOfficePosition } from '../../helpers/staffPosition-helpers';

type TProps = {
  total: number,
  isImageLoading: boolean
}

const ExpensesSubmit = ({ total, isImageLoading }: TProps) => {
  const { t } = useTranslation();
  const { isFormDisabled, isHaveId, initialValues, setIsFieldsUpdated } = useContext(ExpenseContext);
  const { values, errors, isSubmitting, submitForm } = useFormikContext<IExpensesInitialValues>();
  const params: IExpensePageParams = useParams();
  const [ isActiveSubmit, setIsActiveSubmit ] = useState(false);
  const isOfficePosition = checkIsOfficePosition();

  const [ disabled, setDisabled ] = useState<boolean>(false)

  // CHECK IF FIELDS WAS UPDATED AND ENABLED SUBMIT BUTTON
  useEffect(() => {
    if (isHaveId && initialValues) {
      const isFieldsEqual = isEqual(initialValues, values)
      setDisabled(isFieldsEqual)
      setIsFieldsUpdated && setIsFieldsUpdated(!isFieldsEqual)
    }
  }, [ initialValues, isHaveId, values ]) // eslint-disable-line react-hooks/exhaustive-deps

  // SET SUBMIT BUTTON ACTIVE
  useEffect(() => {
    if (isFieldsNotFilled() || disabled) {
      setIsActiveSubmit(false)
      return;
    }
    setIsActiveSubmit(true);
  }, [values, errors, disabled]) // eslint-disable-line

  function isFieldsNotFilled() {
    const isError = (errors.date || (!isOfficePosition && errors.expense_type) || errors.job_number || (errors.expense_items && errors.expense_items.length))
    const IsNotAllRequiredFields = (!values.date || (!isOfficePosition && !values.expense_type) || !(values.expense_items && values.expense_items.length))

    return isError || IsNotAllRequiredFields
  }

  const handleSubmitClick = () => {
    if (isFieldsNotFilled()) {
      const alertData: customAlertProps = {
        title: t('fillInAllRequiredFields'),
        showCancelButton: true,
        cancelButtonText: 'Ok'
      }
      customAlert(alertData);
      submitForm();
      return;
    }

    if (isHaveId) {
      const saveChangesConfirmData: customAlertProps = {
        text: t('areYouSureThatYouWantToChangeYourData'),
        showConfirmButton: true,
        onConfirmClick: () => {
          submitForm();
        },
        confirmButtonText: t('yes'),
        cancelButtonText: t('no'),
        showCancelButton: true,
        textClassName: 'custom-swal__text_padding_top custom-swal__text_big custom-swal__text_bold'
      }
      customAlert(saveChangesConfirmData);
      return;
    } else {
      submitForm();
    }
  }

  return (
    <>
      <p className={ 'mt-8 title' }>{ t('total') }: $ { total.toFixed(2) }</p>
      { isFormDisabled ? null : (
        <>
          <button
            disabled={ isSubmitting || isImageLoading || disabled }
            onClick={ (e) => {
              e.preventDefault();
              handleSubmitClick()
            } }
            type={ 'submit' }
            className={ `button button_full button_common mt-10 ${ isActiveSubmit ? 'button_common_active' : "" }` }
          >
            { isSubmitting || isImageLoading ? (<LoaderSmall/>) : params.expenseId ? t('saveChanges') : t('save') }
          </button>
          {
            !params.expenseId ? (
              <button onClick={ (e) => {
                e.preventDefault();
                handleClose({ t })
              } } className={ 'button button_full button_transparent mt-18px' }>
                { t('close') }
              </button>
            ) : null
          }
        </>
      ) }
    </>
  );
};

export default ExpensesSubmit;