import React from 'react';

export default class ErrorBoundry extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <p>oops... Error while generating page</p>;
    }

    return this.props.children;
  }
}