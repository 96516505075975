export type TLoginSteps = {
  [key: string]: TLoginStepItem
}

export type TLoginStepItem = number;

export const LoginSteps: TLoginSteps = {
  stepLang: 1,
  stepPhoneInput: 2,
  stepCodeConfirm: 3
}