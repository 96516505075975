import React, { useContext, useEffect, useState } from 'react';
import { Modal } from "../Modal";
import arrow from "../../assets/svg/login-back-arrow.svg";
import { useTranslation } from 'react-i18next';
import plus from '../../assets/svg/photo-add-orange.svg'
import SVGTrash from '../../assets/svg-functions/SVGTrash';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ExpenseContext from "../../context/ExpenseContext";
import { addExpenseImages } from '../../helpers/expenses-helpers';
import { IExpenseItem, IExpensesInitialValues, IReceiptObject } from '../../pages/expenses';
import { customAlert, customAlertProps } from "../../helpers/modals/customAlert";
import { useFormikContext } from "formik";
import FullPageLoading from "../FullPageLoading";


type TProps = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  id: string,
  valuesIndex: number
}

const ExpensesImageModal = ({ setShowModal, id, valuesIndex, showModal }: TProps) => {
  const { t } = useTranslation();
  const [ currentItemIndex, setCurrentItemIndex ] = useState(1);
  const { isHaveId, isFormDisabled, setIsImageLoading, isImageLoading } = useContext(ExpenseContext);
  const [ previews, setPreviews ] = useState<Array<IReceiptObject>>([]);
  const { setFieldValue, values } = useFormikContext<IExpensesInitialValues>();
  const [ showToast, setShowToast ] = useState(false);
  const [ isMoreThanOneImage, setIsMoreThanOneImage ] = useState(false);
  const [ isSuccessUpload, setIsSuccessUpload ] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  }

  function ShowConfirmDeleteSwal(deleteFunction: () => void) {
    const settings: customAlertProps = {
      title: t('deleteThisPhoto'),
      showConfirmButton: true,
      onConfirmClick: deleteFunction,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      showCancelButton: true,
    }
    customAlert(settings)
  }

  const handleDeleteServerItem = (deleteReceiptIndex: number) => {
    function DeleteItem() {
      const itemDeleteId = values.expense_items[valuesIndex].id
      // @ts-ignore
      const deleteId = values.expense_items[valuesIndex].receipts[deleteReceiptIndex]?.id
      const currentExpense = values.expense_items.find((oe) => oe.id === itemDeleteId) || values.expense_items[0];
      const currentExpenseIndex = values.expense_items.findIndex((item) => item.id === itemDeleteId);
      const beforeIndexArr = values.expense_items.slice(0, currentExpenseIndex);
      const afterIndexArr = values.expense_items.slice(currentExpenseIndex + 1);

      // @ts-ignore
      const newReceipts = currentExpense.receipts.filter(item => item.id !== deleteId)
      const deleteReceipt = currentExpense.receipts.filter(item => item.id === deleteId)[0];

      let updatedExpense: IExpenseItem =  {
        ...currentExpense,
        receipts: [ ...newReceipts ],
      }

      //
      // IF ITEM IS ADDED JUST NOW
      // THEN DON'T ADD IT TO DELETE ARRAY
      // BECAUSE IT IS NOT SAVED ON SERVER YET
      //
      if (deleteReceipt?.action !== 'add') {
        updatedExpense = {
          ...updatedExpense,
          deleteReceipts: [ ...currentExpense.deleteReceipts || [], deleteReceipt ]
        }
        deleteReceipt.action = 'delete';
        delete deleteReceipt.image;
      }
      setFieldValue(`expense_items`, [ ...beforeIndexArr, updatedExpense, ...afterIndexArr ])

      if (currentExpense.receipts.length === 1) {
        setShowModal(false);
      }

      if (deleteReceiptIndex > 0) {
        setCurrentItemIndex(deleteReceiptIndex);
      } else {
        setCurrentItemIndex(1);
      }
    }

    ShowConfirmDeleteSwal(DeleteItem)
  }

  const handleDelete = (deleteReceiptIndex: number) => {
    const DeleteItem = () => {
      if (values.expense_items && values.expense_items.length) {
        const currentExpense = values.expense_items.find((oe) => oe.uuid === id) || values.expense_items[0];
        const currentExpenseIndex = values.expense_items.findIndex(item => item.uuid === id);
        const beforeIndexArr = values.expense_items.slice(0, currentExpenseIndex);
        const afterIndexArr = values.expense_items.slice(currentExpenseIndex + 1);

        const newReceipts = currentExpense.receipts.filter((item, index) => index !== deleteReceiptIndex)

        const updatedExpense: IExpenseItem = {
          ...currentExpense,
          receipts: [ ...newReceipts ]
        }
        setFieldValue(`expense_items`, [ ...beforeIndexArr, updatedExpense, ...afterIndexArr ])

        if (currentExpense.receipts.length === 1) {
          setShowModal(false);
        }

        if (deleteReceiptIndex > 0) {
          setCurrentItemIndex(deleteReceiptIndex);
        } else {
          setCurrentItemIndex(1);
        }
      }
    }

    ShowConfirmDeleteSwal(DeleteItem)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isHaveId) {
        const previews = values.expense_items?.filter((item, index) => valuesIndex === index)[0]?.receipts
        // @ts-ignore
        setPreviews(previews)
        return;
      }

      const current = values.expense_items?.filter(item => item.uuid === id)[0].receipts
      // @ts-ignore
      setPreviews(current)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ values.expense_items?.filter((item, index) => valuesIndex === index)[0]?.receipts?.length, values.expense_items?.filter((item, index) => valuesIndex === index)[0]?.receipts?.length ])

  // TOAST LOGIC
  let timer: any;

  function showSuccessToast(isSuccess: boolean, isMoreThanOneImage?: boolean) {
    if (isMoreThanOneImage) {
      setIsMoreThanOneImage(isMoreThanOneImage);
    }
    setIsSuccessUpload(isSuccess);
    setShowToast(true)

    timer = setTimeout(() => {
      setShowToast(false);
    }, 2000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    }
  }, [ timer ])
  // END TOAST LOGIC

  return (
    <Modal isShowModal={ showModal }>
      <div className="modal-root__content">
        <div className="expenses-modal__back-header">
          <div className="button expenses-modal__back-button" onClick={ handleClose }>
            <img className="login__back-button-img" src={ arrow } alt="arrow back"/>
            { t('back') }
          </div>
          <p
            className="expenses-modal__back-text">{ previews.length ? currentItemIndex : 0 } of { previews && previews.length }</p>
        </div>

        {
          isImageLoading ? (
            <div>
              <FullPageLoading text={ t('ImageLoaderHelperText') } />
            </div>
          ) : (
            <div className={ 'expenses-modal__gallery' }>
              { previews && previews.length ? (
                <Carousel emulateTouch showArrows={ false } showThumbs={ false } showStatus={ false }
                          showIndicators={ false }
                          preventMovementUntilSwipeScrollTolerance
                          selectedItem={ currentItemIndex - 1 }
                          onChange={ (event) => {
                            setCurrentItemIndex(event + 1)
                          } }
                >
                  {
                    previews.filter((item) => item.action && isHaveId ? item.action !== 'delete' : item).map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <img className={ 'expenses-modal__gallery_item-img' } src={ item.image } alt="preview"/>
                        </React.Fragment>
                      )
                    })
                  }
                </Carousel>
              ) : null }
            </div>
          )
        }

        <div className={ 'expenses-modal__footer' }>
          {/* HIDE ADD IMAGES BUTTON */ }
          {/* IF IMAGES LENGTH === 5  */ }
          <label htmlFor={ `expenses-modal-file-input-${ id }` }>
            { previews.length < 5 ? (
              <>
                <img src={ plus } alt="add file"/>
                <input id={ `expenses-modal-file-input-${ id }` } type="file" name={ 'receipts' }
                       className={ 'display-none' }
                       onChange={ (e) => {
                         addExpenseImages(e, id, setFieldValue, valuesIndex, values, isHaveId, setIsImageLoading, showSuccessToast, setCurrentItemIndex);
                       } } multiple accept="image/*"/>
              </>
            ) : null }
          </label>

          <SVGTrash onClick={ () => {
            if (isFormDisabled) {
              return;
            }

            if (isHaveId && values.expense_items[valuesIndex].id) {
              const deleteReceiptIndex = currentItemIndex - 1;
              handleDeleteServerItem(deleteReceiptIndex);
              return;
            }
            const deleteReceiptIndex = currentItemIndex - 1;
            handleDelete(deleteReceiptIndex);
          } }/>
        </div>
      </div>

      <ExpenseToast isSuccess={ isSuccessUpload } showToast={ showToast } isMoreThanOneImage={ isMoreThanOneImage }/>

    </Modal>
  );
};

export default ExpensesImageModal;

type ExpenseToastProps = {
  isSuccess: boolean
  showToast: boolean
  isMoreThanOneImage: boolean
}

const ExpenseToast = ({ isSuccess, showToast, isMoreThanOneImage }: ExpenseToastProps) => {
  const { t } = useTranslation();
  return (
    <>
      { showToast ? (
        <div className={ `react-hot-toast ${ isSuccess ? "react-hot-toast_success" : "react-hot-toast_error" } ` }>
          { isSuccess ? (
            <p className="react-hot-toast__text react-hot-toast__text_success">
              { isMoreThanOneImage ? t('photosSavedSuccessfully') : t('photoSavedSuccessfully') }
            </p>
          ) : (
            <p className="react-hot-toast__text react-hot-toast__text_error">
              { t('expenseMaxImagesError') }
            </p>
          ) }
        </div>
      ) : null }
    </>
  )
}