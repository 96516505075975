import ForemanDriver from '../assets/svg/position-foreman-driver.svg'
import Foreman from '../assets/svg/position-foreman.svg'
import Driver from '../assets/svg/position-driver.svg'
import Helper from '../assets/svg/position-helper.svg'
import ExpensesConnected from '../assets/svg/expenses-connect-to-job.svg'
import ExpensesNotConnected from '../assets/svg/expenses-not-connect-to-job.svg'
import { useTranslation } from 'react-i18next'

export type TJobsLocationsListItem = {
  id: number,
  name: string,
}

export type TJobsPositionsListItem = {
  id: number,
  name: string
  icon: string
}

const UseCommonApiData = () => {
  const { t } = useTranslation();

  const jobsLocationsList: Array<TJobsLocationsListItem> = [
    {
      "id": 1,
      "name": "Moving"
    },
    {
      "id": 2,
      "name": "Warehouse"
    },
    {
      "id": 3,
      "name": "Office"
    },
    {
      "id": 4,
      "name": t('other')
    }
  ]

  const jobsPositionsList: Array<TJobsPositionsListItem> = [
    {
      "id": 1,
      "name": t('foremanDriver'),
      icon: ForemanDriver
    },
    {
      "id": 2,
      "name": t('foreman'),
      icon: Foreman
    },
    {
      "id": 3,
      "name": t('driver'),
      icon: Driver
    },
    {
      "id": 4,
      "name": t('helper'),
      icon: Helper
    }
  ]

  const expensesTypes: Array<TJobsPositionsListItem> = [
    {
      "id": 1,
      "name": t('connectedToAJob'),
      "icon": ExpensesConnected
    },
    {
      "id": 2,
      "name": t('notConnectedToAJob'),
      "icon": ExpensesNotConnected
    }
  ]

  return {
    jobsLocationsList,
    jobsPositionsList,
    expensesTypes
  }
};

export default UseCommonApiData;