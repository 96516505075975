import { defaultExpenseImageId } from "../../pages/expenses";
import { ExpensesActionTypes, TExpensesInitialState } from "./expenses.types";
import { AnyAction } from 'redux'

const INITIAL_STATE: TExpensesInitialState  = {
  values: {
    job_number: undefined,
    date: undefined,
    expense_items: [
      {
        uuid: defaultExpenseImageId,
        amount: "",
        description: "",
        receipts: [],
      }
    ],
    expense_type: 1  // expensesTypes[0].id
  }
};

const ExpensesReducer = (state: TExpensesInitialState = INITIAL_STATE, action: AnyAction): TExpensesInitialState => {
  switch (action.type) {
    case ExpensesActionTypes.setExpenseValues:
      return {
        ...state,
        values: action.payload
      };

    case ExpensesActionTypes.resetValues:
      return {
        ...state,
        values: {
          job_number: undefined,
          date: undefined,
          expense_items: [
            {
              uuid: defaultExpenseImageId,
              amount: "",
              description: "",
              receipts: [],
            }
          ],
          expense_type: 1  // expensesTypes[0].id
        }
      };

    default:
      return state;
  }
};

export default ExpensesReducer;