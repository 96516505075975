import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ExpensesMain from '../components/expenses/ExpensesMain';
import MainLayout from "../components/MainLayout";
import PageTitle from "../components/PageTitle";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useCommonApiData from '../hooks/useCommonApiData';
import { v4 as uuidv4 } from 'uuid';
import ExpenseContext, { ExpensesContextType } from '../context/ExpenseContext';
import ExpensesList from '../components/expenses/ExpensesList';
import { customAlert, customAlertProps } from "../helpers/modals/customAlert";
import { staffAPI } from '../API';
import SomethingWentWrongModal from '../helpers/modals/SomethingWentWrongModal';
import Spinner from "../components/Spinner";
import { IExpenseResponseItem } from "../types/modelTypes";
import { useHistory, useParams } from "react-router-dom";
import arrow from "../assets/svg/login-back-arrow.svg";
import { useTypedSelector } from "../redux/useTypedSelector";
import ExpensesSubmit from '../components/expenses/ExpensesSubmit';
import { handleBackClick } from "../helpers/handleBackFromSummary";
import { checkIsOfficePosition } from '../helpers/staffPosition-helpers';

export interface IExpensesInitialValues {
  job_number: string | undefined,
  date: Date | undefined,
  expense_items: Array<IExpenseItem>,
  expense_type: number | null
}

export interface IExpenseItem {
  id?: string | number
  amount: number | string
  description: string
  receipts: Array<IReceiptObject>
  deleteReceipts?: Array<IReceiptObject>
  uuid: string | null
  admin_version?: any
}

interface IUpdatedExpenseItem {
  id?: string | number | null,
  amount: string | number,
  description: string,
  uuid: string | null,
  action?: TEditingAction,
}

export type TEditingAction = "delete" | "update" | "add"

export interface IReceiptObject {
  id?: number
  action?: TEditingAction
  image?: string
}

export interface IExpensePageParams {
  expenseId?: string
}

export const defaultExpenseImageId = 'main'

const Expenses = () => {
  const { t } = useTranslation();
  const { expensesTypes } = useCommonApiData();
  const [ total, setTotal ] = useState(0);
  const storeValues = useTypedSelector(state => state.expenses.values)

  const [ initialValues, setInitialValues ] = useState<IExpensesInitialValues>(storeValues)
  const [ loading, setLoading ] = useState(false);
  const [ isReviewed, setIsReviewed ] = useState(false)
  const [ isHaveId, setIsHaveId ] = useState(false)
  const [ isFormDisabled, setIsFormDisabled ] = useState(false)
  const [ isEditedByAdmin, setIsEditedByAdmin ] = useState(false)
  const [ isImageLoading, setIsImageLoading ] = useState(false)
  const [ currentExpense, setCurrentExpense ] = useState<IExpenseResponseItem | null>(null)
  const [ isFieldsUpdated,  setIsFieldsUpdated ] = useState(false)
  const params: IExpensePageParams = useParams();
  const history = useHistory();
  const isOfficePosition = checkIsOfficePosition()

  const ExpensesSchema = Yup.object().shape({
    date: Yup.string()
      .required(t('expensesDateWarning')),
    expense_type: isOfficePosition
                    ? Yup.number().nullable(true).default(null)
                    : Yup.number().required(t('expensesDateWarning')),
    job_number: Yup.string().when('expense_type', {
      is: expensesTypes[0].id,
      then: isOfficePosition
              ? Yup.string().default('')
              : Yup.string().required(t('thisFieldIsRequired')),
      otherwise: Yup.string()
    }),
    expense_items: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string().required(t('thisFieldIsRequired')),
        description: Yup.string(),
        receipts: Yup.array().min(1, t('thisFieldIsRequired'))
      }).required(t('thisFieldIsRequired'))
    ).required(t('thisFieldIsRequired')),
  });

  const handleAddExpenseClick = (setFieldValue: any, values: any) => {
    setFieldValue('expense_items', [ ...values.expense_items, {
      uuid: uuidv4(),
      amount: "",
      description: "",
      receipts: [],
      deleteReceipts: []
    } ])
  }

  const contextValue: ExpensesContextType = {
    handleAddExpenseClick,
    isReviewed,
    setIsReviewed,
    isHaveId,
    currentExpense,
    isFormDisabled,
    setTotal,
    isEditedByAdmin,
    setIsImageLoading,
    initialValues,
    setIsFieldsUpdated,
    isImageLoading
  }

  //
  // INITIAL VALUES SETTING
  //
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (params?.expenseId) {
      setLoading(true);
      setIsHaveId(true);
      staffAPI.expenses.retrieveSingleExpense(params.expenseId).then((res: any) => {
        // @ts-ignore
        const item: IExpenseResponseItem = res.data;

        const { job_number, date, expense_type } = item.admin_version

        // PREPARE INITIAL DATA
        const notReviewedData: any = {
          job_number: item.job_number,
          // @ts-ignore
          date: new Date(item.date.replace(/-/g, '/')),  // eslint-disable-line
          expense_type: item.expense_type?.id,
          expense_items: item.expense_items.map(item => ({
            ...item,
            receipts: item.receipts.map(item => ({
              id: item.id,
              image: item.receipt,
              action: null
            })),
            deleteReceipts: [],
            amount: Number(item.amount).toFixed(2),
          }))
        }


        const reviewedData: any = {
          job_number: job_number,
          // @ts-ignore
          date: new Date(date.replace(/-/g, '/')), // eslint-disable-line
          expense_type: expense_type?.id,
          expense_items: item.expense_items.filter(item => item.status === '_default').map(item => ({
            ...item,
            receipts: item.receipts.map(item => ({
              id: item.id,
              image: item.receipt,
              action: null
            })),
            amount: Number(item.amount).toFixed(2),
          }))
        }

        // SET INITIAL FORM VALUES
        if (item.is_editable) {
          setInitialValues(notReviewedData);
          setCurrentExpense(item);
        } else {
          setInitialValues(reviewedData);

          const updatedItems = item.expense_items.filter((item) => item.status !== '_default')
          const defaultItems = item.expense_items.filter((item) => item.status === '_default')
          const finalItem = {
            ...item,
            expense_items: [ ...defaultItems, ...updatedItems ]
          }
          setCurrentExpense(finalItem);
        }

        // UPDATE STATUS
        setIsReviewed(item.status === '_approved')

        // UPDATE EDITABLE STATUS
        setIsFormDisabled(!item.is_editable)

        // UPDATE IS EDIT FIELD
        setIsEditedByAdmin(item.changed_by_admin || false)

        // REMOVE RED MARK
        if (item.has_unseen_changes_by_admin) {
          staffAPI.expenses.updateMarkStatus(params.expenseId).then(res => {
            console.log(res.data);
          })
        }
      }).catch(() => {
        SomethingWentWrongModal(t)
      }).finally(() => {
        setLoading(false);
      })
    } else if (!params.expenseId) {
      setLoading(true);

      setInitialValues(storeValues);
      setIsReviewed(false);
      setIsHaveId(false);
      setCurrentExpense(null);
      setIsFormDisabled(false);
      setIsEditedByAdmin(false);

      timer = setTimeout(() => {
        setLoading(false)
      }, 400)
    }

    return () => {
      clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ params ])

  if (loading) {
    return <Spinner className={ 'h-screen' }/>
  }

  return (
    <ExpenseContext.Provider value={ contextValue }>
      {
        params.expenseId ? (
          <div className="button-back--parent">
            <div className="button-back-container button-back-container_padding">
              <div className="button expenses-modal__back-button" onClick={  () => {handleBackClick(history, isHaveId, isFieldsUpdated, t)} }>
                <img className="login__back-button-img" src={ arrow } alt="arrow back"/>
                { t('back') }
              </div>
              <p
                className="expenses-modal__back-text">{ t('expenseRecord') }</p>
            </div>
          </div>
        ) : null
      }
      <MainLayout className={ 'container_small' }>
        {
          !params.expenseId ? (
            <PageTitle title={ t('expenses') } className={ 'pt-8 page-title_padding' }/>
          ) : null
        }

        <Formik<IExpensesInitialValues>
          initialValues={ initialValues }
          onSubmit={ async (values) => {
            //@ts-ignore
            const date = `${ values.date?.getFullYear() }-${ values?.date?.getMonth() + 1 }-${ values.date?.getDate() }`

            const expenseItems = values.expense_items.map(item => ({
              amount: item.amount,
              description: item.description,
              receipts: item.receipts
            }));

            if (isOfficePosition) values.expense_type = null;

            const data = {
              ...values,
              expense_items: expenseItems,
              date: date,
            }
            let editData = {}
            let addedItems: Array<IUpdatedExpenseItem> = []

            // REQUEST WHEN UPDATE EXPENSE
            if (isHaveId) {
              const updatedItems: Array<IUpdatedExpenseItem> = values.expense_items.reduce((acc: any, item): IUpdatedExpenseItem | undefined => {
                const returnItem = {
                  id: item.id,
                  amount: item.amount,
                  description: item.description,
                  uuid: item.uuid || null,
                  deleteReceipts: item.deleteReceipts,
                  receipts: item.receipts,
                  action: "update"
                }
                if (item.id) {
                  acc.push(returnItem)
                  return acc;
                }
                return acc;
              }, [])

              const oldItems = currentExpense?.admin_version?.expense_items || []
              const deletedItems: Array<IUpdatedExpenseItem> = oldItems.reduce((acc: any, item) => {
                const isDeleted = updatedItems.every((i) => {
                  return i.id !== item.id
                })
                if (isDeleted) {
                  const returnItem = {
                    id: item.id,
                    amount: item.amount,
                    description: item.description,
                    deleteReceipts: [],
                    uuid: item.uuid || null,
                    receipts: [],
                    action: "delete"
                  }
                  acc.push(returnItem)
                  return acc
                }
                return acc
              }, [])

              addedItems = values.expense_items.reduce((acc: any, item): IUpdatedExpenseItem | undefined => {
                const resItem: IUpdatedExpenseItem = {
                  id: null,
                  amount: item.amount,
                  description: item.description,
                  uuid: item.uuid || null,
                  // @ts-ignore
                  deleteReceipts: item.deleteReceipts,
                  receipts: item.receipts,
                  action: "add"
                }
                if (!item.id) {
                  acc.push(resItem);
                  return acc;
                }
                return acc;
              }, [])

              const updatedExpenseItems: Array<IUpdatedExpenseItem> = [ ...updatedItems, ...deletedItems, ...addedItems ]

              const ExpenseItemsWithUpdateReceipts = updatedExpenseItems.map(item => {
                // @ts-ignore
                const receipts = item.receipts.filter(item => item.action !== null).concat(item.deleteReceipts);
                return {
                  id: item.id,
                  amount: item.amount,
                  description: item.description,
                  uuid: item.uuid || null,
                  receipts,
                  action: item.action
                }
              })

              editData = {
                ...values,
                date: date,
                expense_items: ExpenseItemsWithUpdateReceipts
              }
            }

            const RequestUrl = isHaveId ? staffAPI.expenses.updateExpense(params.expenseId, editData) : staffAPI.expenses.createNewExpense(data)

            // REQUEST WHEN CREATE NEW EXPENSE
            await RequestUrl.then(() => {
              const alertData: customAlertProps = {
                title: t('success'),
                text: isHaveId ? t('theRecordWasUpdated') : t('theRecordWasAdded'),
                icon: 'success',
                showConfirmButton: true,
                showCancelButton: false,
                onConfirmClick: () => {
                  window.location.reload()
                },
                confirmButtonText: t('Ok'),
                confirmButtonClass: 'custom-swal__btn custom-swal__btn-cancel'
              }
              customAlert(alertData);
            }).catch(() => {
              SomethingWentWrongModal(t)
            })
          } }
          validationSchema={ ExpensesSchema }
        >
          <Form>
            <fieldset disabled={ isFormDisabled }>
              <div className={ 'pt-8' }>
                <ExpensesMain/>

                <ExpensesList/>

                <ExpensesSubmit total={ total } isImageLoading={ isImageLoading }/>
              </div>
            </fieldset>
          </Form>
        </Formik>
      </MainLayout>
    </ExpenseContext.Provider>
  );
}

export default Expenses;
