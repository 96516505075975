import React, { useContext } from 'react';
import InfoContainer from "../info-blocks/InfoContainer";
import ExpensesOtherInfo from "./ExpensesOtherInfo";
import { defaultExpenseImageId, IExpensesInitialValues } from "../../pages/expenses";
import ExpenseContext from "../../context/ExpenseContext";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { customAlert, customAlertProps } from "../../helpers/modals/customAlert";
import { IServerExpenseItem } from "../../types/modelTypes";
import ExpensesReadOnlyOtherInfo from "./ExpensesReadOnlyOtherInfo";

const ExpensesList = () => {
  const { t } = useTranslation();
  const { handleAddExpenseClick, isHaveId, currentExpense, isFormDisabled } = useContext(ExpenseContext);
  const { setFieldValue, values } = useFormikContext<IExpensesInitialValues>();

  const handleDeleteExpenseClick = (id: string, index: number) => {
    const settings: customAlertProps = {
      title: t('deleteExpense'),
      showConfirmButton: true,
      onConfirmClick: DeleteItem,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      showCancelButton: true,
    }
    customAlert(settings)

    function DeleteItem() {
      const newExpenseItems = values.expense_items.filter((item, idx) => idx !== index);
      setFieldValue('expense_items', [ ...newExpenseItems ])
    }
  }

  return (
    <>
      {
        isHaveId ? (
          <>
            {
              // @ts-ignore
              values.expense_items.filter(item => item.id !== currentExpense?.expense_items[0].id).map((item: IServerExpenseItem, index) => {
                // @ts-ignore
                const lastElemId = values.expense_items.slice(-1)[0].id;
                return (
                  <InfoContainer
                    key={ item.uuid }
                    className={ 'mt-6' }
                    title={ `${ t('expense') } №${ index + 2 }` }
                    showDeleteIcon={ true }
                    handleDeleteClick={ () => {
                      // @ts-ignore
                      handleDeleteExpenseClick(item.uuid, index + 1)
                    } }
                    isDisabled={ isFormDisabled }
                  >
                    <ExpensesOtherInfo
                      showAddButton={ !isFormDisabled && values.expense_items.length < 5 && item.id === lastElemId }
                      onClick={ () => {
                        handleAddExpenseClick && handleAddExpenseClick(setFieldValue, values)
                      } }
                      // @ts-ignore
                      id={ item.uuid || '' }
                      valuesIndex={ index + 1 }
                      disabled={ !(values.expense_items[index + 1]?.amount && values.expense_items[index + 1]?.description && values.expense_items[index + 1]?.receipts?.length) }
                    />
                  </InfoContainer>
                )
              })
            }
            {
              isFormDisabled && currentExpense && currentExpense.expense_items && currentExpense.expense_items.length ? (
                currentExpense.expense_items.filter(item => item.status !== '_default').map((item: IServerExpenseItem, index) => {
                  return (
                    <InfoContainer
                      key={ item.uuid }
                      className={ 'mt-6' }
                      title={ `${ t('expense') } №${ index + 1 + values.expense_items.length }` }
                      isDisabled={ isFormDisabled }
                      isDeleted={ item.status === '_deleted' }
                      isAdded={ item.status === '_added' }
                    >
                      <ExpensesReadOnlyOtherInfo
                        id={ item.id || 0 }
                        item={ item }
                      />
                    </InfoContainer>
                  )
                })
              ) : null
            }
          </>
        ) : (
          <>
            {
              values.expense_items && values.expense_items.length ? (
                values.expense_items.filter(item => item.uuid !== defaultExpenseImageId).map((item, index) => {
                  const lastElemId = values.expense_items.slice(-1)[0].uuid;
                  return (
                    <InfoContainer
                      key={ item.uuid }
                      className={ 'mt-6' }
                      title={ `${ t('expense') } №${ index + 2 }` }
                      showDeleteIcon={ true }
                      handleDeleteClick={ () => {
                        // @ts-ignore
                        handleDeleteExpenseClick(item.uuid, index + 1)
                      } }
                      isDisabled={ isFormDisabled }
                    >
                      <ExpensesOtherInfo
                        showAddButton={ !isFormDisabled && values.expense_items.length < 5 && item.uuid === lastElemId }
                        onClick={ () => {
                          handleAddExpenseClick && handleAddExpenseClick(setFieldValue, values)
                        } }
                        id={ item.uuid || '' }
                        valuesIndex={ index + 1 }
                        disabled={ !(values.expense_items[index + 1]?.amount && values.expense_items[index + 1]?.description && (values.expense_items[index + 1]?.receipts && values.expense_items[index + 1]?.receipts?.length)) }
                      />
                    </InfoContainer>
                  )
                })
              ) : null
            }
          </>
        )
      }
    </>
  );
};

export default ExpensesList;