import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import SvgWagesIcon from "../assets/svg-functions/SVGWagesIcon";
import SvgExpensesIcon from "../assets/svg-functions/SVGExpensesIcon";
import SvgSummaryIcon from "../assets/svg-functions/SVGSummaryIcon";
import URLS from "../resources/URLS";
import { NavLink, Route, useHistory } from "react-router-dom";
import { checkIsOfficePosition } from '../helpers/staffPosition-helpers';

type TBottomNavbarItem = {
  id: number,
  text: string,
  icon: ({ color }: { color?: string | undefined; }) => JSX.Element,
  url: string
}

type TBottomNavbarProps = {
  showTotal?: boolean,
  total?: number
}



const BottomNavbar: React.FC<TBottomNavbarProps> = ({ showTotal = false, total = 0.00 }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isOfficePosition = checkIsOfficePosition();

  const listDefaultValues: Array<TBottomNavbarItem> = [
    {
			id: 1,
			text: `${t("wages")}`,
			icon: SvgWagesIcon,
			url: URLS.wages,
		},
    {
      id: 2,
      text: `${ t('expenses') }`,
      icon: SvgExpensesIcon,
      url: URLS.expenses
    },
    {
      id: 3,
      text: `${ t('summary') }`,
      icon: SvgSummaryIcon,
      url: URLS.summary
    },
  ]

  const [list, setList] = useState<Array<TBottomNavbarItem>>(listDefaultValues);

  const handleClick = (item: TBottomNavbarItem) => {
    history.push(item.url)
  }

	useLayoutEffect(() => {
		if (isOfficePosition) {
      const newListValues = listDefaultValues.slice(1);
      setList(newListValues);
    }
	}, []); // eslint-disable-line

  return (
    <>
      { showTotal ? (
        <div className={ 'bottom-navbar__total' }>
          <p className={ 'bottom-navbar__total-text' }>{ t('summaryTotalPay') }</p>
          <p className={ 'bottom-navbar__total-text' }>$ { total.toFixed(2) }</p>
        </div>
      ) : null }
      <nav className="bottom-navbar">
        { list.map(item => {
          return (
            <div key={item.id} className={ 'bottom-navbar__item' } onClick={ () => {
              handleClick(item)
            } }>
              <Route path={ item.url } children={ ({ match }) => (
                <>
                  { <item.icon color={ match ? '#FB8D57' : "#788797" }/> }
                  <NavLink to={ item.url } activeClassName="bottom-navbar__item-text_active"
                           className={ `bottom-navbar__item-text ` }>
                    { item.text }
                  </NavLink>
                </>
              ) }
              />
            </div>
          )
        }) }
      </nav>
    </>
  )
}

export default BottomNavbar;
