import React from 'react';

const SvgWagesIcon = ({ color = '' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.85 13.2501C21.7141 13.0144 21.5167 12.8201 21.2789 12.688C21.0411 12.5559 20.7719 12.491 20.5 12.5001H19C18.6022 12.5001 18.2206 12.6582 17.9393 12.9395C17.658 13.2208 17.5 13.6023 17.5 14.0001C17.5 14.3979 17.658 14.7795 17.9393 15.0608C18.2206 15.3421 18.6022 15.5001 19 15.5001H20.5C20.8978 15.5001 21.2794 15.6582 21.5607 15.9395C21.842 16.2208 22 16.6023 22 17.0001C22 17.3979 21.842 17.7795 21.5607 18.0608C21.2794 18.3421 20.8978 18.5001 20.5 18.5001H19C18.7281 18.5093 18.4589 18.4443 18.2211 18.3122C17.9833 18.1801 17.7859 17.9858 17.65 17.7501"
        stroke={ color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.75 18.5V20M19.75 11V12.5V11Z" stroke={ color } strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M6.5509 16.8753L14.0964 13.5028M6.5509 16.8753L3.17838 9.32981M6.5509 16.8753L11.4194 4.13762"
            stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default SvgWagesIcon;