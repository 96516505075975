import React, { createContext } from 'react';
import { IExpenseResponseItem } from "../types/modelTypes";
import { IExpensesInitialValues } from "../pages/expenses";

export interface ExpensesContextType {
  handleAddExpenseClick: (setFieldValue: any, values: any) => void,
  isReviewed: boolean,
  setIsReviewed: React.Dispatch<React.SetStateAction<boolean>>
  isHaveId: boolean,
  currentExpense: IExpenseResponseItem | null,
  isFormDisabled: boolean,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  isEditedByAdmin?: boolean,
  setIsImageLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  initialValues: IExpensesInitialValues,
  setIsFieldsUpdated: React.Dispatch<React.SetStateAction<boolean>>,
  isImageLoading: boolean
}

const ExpenseContext = createContext<Partial<ExpensesContextType>>({
  isReviewed: false,
  isHaveId: false,
  isFormDisabled: false,
  isEditedByAdmin: false,
  isImageLoading: false
});

export default ExpenseContext