import URLS from "../resources/URLS";
import handleClose from "./modals/handleClosePage";
import { TFunction } from "react-i18next";

export function handleBackClick(history: any,isHaveId: boolean, isFieldsUpdated: boolean, t: TFunction<"translation">) {
  function goBack() {
    history.push(URLS.summary)
  }

  if (isHaveId && isFieldsUpdated) {
    handleClose({ t, onClick: goBack })
    return;
  }

  goBack()
}