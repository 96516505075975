const URLS = {
	login: `/login`,
	home: '/home',
	wages: '/wages',
	singleWage: `/summary/wages/:wageId`,
	singleWageFunc: (id: number) => `/summary/wages/${id}`,
	expenses: "/expenses",
	singleExpense: `/summary/expenses/:expenseId`,
	singleExpenseFunc: (id: number) => `/summary/expenses/${id}`,
	summary: "/summary"
}

export default URLS;