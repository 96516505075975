import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ErrorBoundry from './components/ErrorBoundry';
import PrivateRoute from './components/PrivateRoute';
import Spinner from './components/Spinner';
import URLS from "./resources/URLS";
import { localStorageNames } from "./resources/localStorageNames";

const Login = lazy(() => import('./pages/login'));
const Wages = lazy(() => import('./pages/wages'));
const Expenses = lazy(() => import('./pages/expenses'));
const Summary = lazy(() => import('./pages/summary'));
const NotFound = lazy(() => import('./pages/404'));
const Home = lazy(() => import("./pages/home"));

function App() {
  return (
    <ErrorBoundry>
      <Router>
        <Suspense fallback={ <Spinner className={ 'h-screen' }/> }>
          <div className='app_wrapper'>
            <Switch>
              <Route exact path="/">
                { !localStorage.getItem(localStorageNames.token) ?
                  <Redirect to={ URLS.login }/> :
                  <Redirect to={ URLS.home }/>
                }
              </Route>

              <Route exact path={ URLS.login } component={ Login }/>
              <Route exact path={ URLS.home } component={ Home }/>

              <PrivateRoute path={ URLS.wages } exact component={ Wages }/>
              <PrivateRoute path={ URLS.singleWage } component={ Wages }/>
              <PrivateRoute path={ URLS.expenses } exact component={ Expenses }/>
              <PrivateRoute path={ URLS.singleExpense } component={ Expenses }/>
              <PrivateRoute path={ URLS.summary } component={ Summary }/>
              <Route path='*' component={ NotFound }/>
            </Switch>
          </div>
        </Suspense>
      </Router>
    </ErrorBoundry>
  );
}

export default App;
