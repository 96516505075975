import React from 'react';

const Spinner = ({ className = '', text = '' }) => {
  return (
    <div className={ `sk-folding-cube-container ${ className }` }>
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"/>
        <div className="sk-cube2 sk-cube"/>
        <div className="sk-cube4 sk-cube"/>
        <div className="sk-cube3 sk-cube"/>
      </div>
      {text ? (
        <p className="spinner-text">{ text }</p>
      ) : null}
    </div>
  );
};

export default Spinner;