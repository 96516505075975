import { SummarySwitchType, SummaryWeekType } from "../../context/SummaryContext";
import {  SettingsActionTypes, SettingsPayload, TSettingsInitialState } from "./settings.types";
import { AnyAction } from 'redux'


const INITIAL_STATE: TSettingsInitialState = {
  settings: null,
  summary: 'wages',
  summaryWeek: 'current'
};

interface IReturnState {
  settings: SettingsPayload | null;
  summary: SummarySwitchType
  summaryWeek: SummaryWeekType
}

const SettingsReducer = (state = INITIAL_STATE, action: AnyAction): IReturnState => {
  switch (action.type) {
    case SettingsActionTypes.setWagesSettings:
      const { material_comm_commission }: SettingsPayload = action.payload;
      return {
        ...state,
        settings: {
          material_comm_commission: Number(material_comm_commission)
        }
      };

    case SettingsActionTypes.setCurrentSummary:
      return {
        ...state,
        summary: action.payload
      };

    case SettingsActionTypes.setSummaryWeek:
      return {
        ...state,
        summaryWeek: action.payload
      };

    default:
      return state;
  }
};

export default SettingsReducer;