import { TFunction } from "react-i18next";
import { customAlert, customAlertProps } from "./customAlert";

type TProps = {
  t: TFunction<"translation">,
  onClick?: () => void
}

const handleClose = ({ t, onClick }: TProps) => {
  const alertData: customAlertProps = {
    title: t('closeThePage'),
    text: t('confirmClose'),
    showConfirmButton: true,
    onConfirmClick: () => {
      if (onClick) {
        onClick();
        return;
      }

      window.location.reload()
    },
    confirmButtonText: t('yes'),
    cancelButtonText: t('no'),
    showCancelButton: true,
  }
  customAlert(alertData);
}

export default handleClose;