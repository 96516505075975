import React, { useEffect } from 'react';

type TProps = {
  children: React.ReactNode,
  isShowModal: boolean,
  className?: string,
  isHideScroll?: boolean
}

export const Modal = ({ children, isShowModal, className = '', isHideScroll = false }: TProps) => {

  useEffect(() => {
    if (isHideScroll) {
      return;
    }

    const body = document.querySelector("body");
    let scrollY: number;

    function preventDefault(e: any) {
      e.preventDefault();
    }

    function syncHeight() {
      document.documentElement.style.setProperty(
        "--window-inner-height",
        `${window.innerHeight}px`
      );
    }

    window.addEventListener("resize", syncHeight);

    const modal = document.querySelector(".modal-root");

    if (isShowModal && body) {
      body.style.overflow = 'hidden';
      scrollY = window.scrollY;

      document.documentElement.classList.add("modal-is-locked");

      modal && modal.classList.add("modal-is-open");

      modal && modal.addEventListener("pointermove", preventDefault);
    }

    return () => {
      if (body) {
        window.removeEventListener("resize", syncHeight);

        body.style.overflow = 'auto';

        document.documentElement.classList.remove("modal-is-locked");

        modal && modal.classList.remove("modal0is-open");

        modal && modal.removeEventListener("pointermove", preventDefault);

        // restore scroll position
        window.scrollTo(0, scrollY);
      }
    }
  }, [isShowModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={ `modal-root ${className}` }>
    { children }
  </div>
}
