import { staffAPI } from "../API";
import { localStorageNames } from "../resources/localStorageNames";
import { positions } from "../resources/positions";
import URLS from "../resources/URLS";

export const setStaffPosition = (history: any) => {
	staffAPI.login
		.retrieveCurrentUser()
		.then((res) => {
			localStorage.setItem(
				localStorageNames.staffPosition,
				//@ts-ignore
				JSON.stringify(res.data?.position)
			);
			//@ts-ignore
			if (res.data?.position.id === positions.office.id)
				history.push(URLS.expenses);
			else history.push(URLS.wages);
		})
		.catch((err) => {
			console.log(err);
		});
};

export const checkIsOfficePosition = () => {
	const staffPosition = JSON.parse(
		//@ts-ignore
		localStorage.getItem(localStorageNames.staffPosition)
	);
	return staffPosition?.id === positions.office.id;
};
