import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDrCtS4RY_s9vDalcLiU14UStLLRfhFbUY",
  authDomain: "osmon-dev.firebaseapp.com",
  projectId: "osmon-dev",
  storageBucket: "osmon-dev.appspot.com",
  messagingSenderId: "1071063585330",
  appId: "1:1071063585330:web:53e402e691a7d0ba11a22b"
};

firebase.initializeApp(firebaseConfig);
// @ts-ignore
const auth = firebase.auth();

export { firebase, auth }

