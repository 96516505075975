import React, { ComponentType, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import URLS from "../resources/URLS";
import { localStorageNames } from "../resources/localStorageNames";
import { useDispatch } from "react-redux";
import { resetLoginData } from "../redux/Login/login.actions";
import { checkIsOfficePosition } from '../helpers/staffPosition-helpers';

type PrivateRouterProps = {
  path: string,
  exact?: boolean,
  // @ts-ignore
  component: ComponentType<RouteComponentProps<any, StaticContext, unknown>> | ComponentType<any> | undefined
}

const PrivateRoute = ({ path = '', exact = false, component }: PrivateRouterProps) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem(localStorageNames.token))
  const dispatch = useDispatch();
  const isOfficePosition = checkIsOfficePosition();

  useEffect(() => {
    const timer = setInterval(() => {
      const newToken = localStorage.getItem(localStorageNames.token)

      if (!newToken) {
        dispatch(resetLoginData())
      }

      setToken(newToken);
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, []) // eslint-disable-line

  return (
    <>
      {
        !token
        ? <Redirect to={ URLS.login }/>
        : isOfficePosition && (path===URLS.wages || path===URLS.singleWage)
        ? <Redirect to={ URLS.expenses }/>
        : <Route path={ path } exact={ exact } component={ component }/>
      }
    </>
  );
};

export default PrivateRoute;
