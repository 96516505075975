import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgTrash from "../../assets/svg-functions/SVGTrash";
import reviewed from '../../assets/svg/reviewed.svg'
import notReviewed from '../../assets/svg/not-reviewed.svg'
import EditedByAdmin from '../EditedByAdmin';

type TInfoContainerProps = {
  children: React.ReactNode,
  className?: string,
  title: string,
  required?: boolean,
  handleDeleteClick?: () => void,
  showDeleteIcon?: boolean,
  showStatus?: boolean,
  isReviewed?: boolean,
  isDisabled?: boolean,
  isDeleted?: boolean,
  isAdded?: boolean,
  isEditedByAdmin?: boolean
}

const InfoContainer: React.FC<TInfoContainerProps> = ({
                                                        className = '',
                                                        title = '',
                                                        required = false,
                                                        showDeleteIcon = false,
                                                        handleDeleteClick,
                                                        showStatus = false,
                                                        isReviewed = false,
                                                        isDisabled = false,
                                                        isDeleted = false,
                                                        isAdded = false,
                                                        isEditedByAdmin = false,
                                                        children
                                                      }) => {
  const { t } = useTranslation();

  return (
    <div
      className={ `${ className } info-container ${ isDeleted ? 'info-container_deleted' : null } ${ isAdded ? 'info-container_added' : null }` }>
      { showStatus ? (
          <div className="info-container__status_margin">
            {
              isReviewed ? (
                <>
                  <p className="info-container__status info-container__status_success">{ t('reviewed') }
                    <img className={ 'info-container__status-img' } src={ reviewed } alt="reviewed"/>
                  </p>
                </>

              ) : (
                <p
                  className="info-container__status info-container__status_error">{ t('notReviewed') }
                  <img className={ 'info-container__status-img' } src={ notReviewed } alt="not reviewed"/>
                </p>
              )
            }
            { isEditedByAdmin ? (
              <EditedByAdmin/>
            ) : null }
          </div>
      ) : null }
      <div className={ 'info-container__header' }>
        <h2
          className={ `info-container__title ${ isDeleted ? 'info-container__title_deleted' : null } ${ isAdded ? 'info-container__title_added' : null }` }>{ title }{ required ?
          <span className="info-container__title-required">*</span> : null }</h2>
        { showDeleteIcon ? (
          <SvgTrash onClick={ isDisabled ? () => {
          } : handleDeleteClick }/>
        ) : null }
      </div>
      { isDeleted ? (
        <p className="info-container__deleted-text">{ t('deletedByAdmin') }</p>
      ) : null }
      { isAdded ? (
        <p className="info-container__added-text">{ t('addedByAdmin') }</p>
      ) : null }

      { children }
    </div>
  );
};

export default InfoContainer;