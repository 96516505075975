import { IExpensesInitialValues } from "../../pages/expenses";
import { BaseAction } from "../types";

export enum ExpensesActionTypes {
  setExpenseValues = 'setExpenseValues',
  resetValues = 'resetValues'
}

export type TExpensesInitialState = {
  values: IExpensesInitialValues
}

export type setExpensesValuesActionType = BaseAction<ExpensesActionTypes, IExpensesInitialValues>

export type resetExpensesValuesActionType = BaseAction<ExpensesActionTypes, IExpensesInitialValues>