import React from 'react';

const SvgSummaryIcon = ({ color = '' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 19V4.88235C5.5 4.38312 5.71071 3.90434 6.08579 3.55133C6.46086 3.19832 6.96957 3 7.5 3H17.5C18.0304 3 18.5391 3.19832 18.9142 3.55133C19.2893 3.90434 19.5 4.38312 19.5 4.88235L19.5 19M9.5 7.76471H15.5M9.5 11.5294H15.5M13.5 15.2941H15.5"
        stroke={ color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M19.5 19C19.5 19.5304 19.2893 20.0391 18.9142 20.4142C18.5391 20.7893 18.0304 21 17.5 21L7.5 21C6.96957 21 6.46086 20.7893 6.08578 20.4142C5.71071 20.0391 5.5 19.5304 5.5 19"
        stroke={ color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SvgSummaryIcon;