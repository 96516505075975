import React from 'react';
import { Modal } from "./Modal";
import Spinner from './Spinner';

const FullPageLoading = ({ text = '' }) => {
  return (
    <Modal isShowModal={ true } isHideScroll>
      <Spinner className="h-screen flex-col all-center" text={ text }/>
    </Modal>
  );
};

export default FullPageLoading;