import React from 'react';

const SvgPhoto = ({color = '#FB8D57', className = ''}) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M6.33333 8.16669H7.5C8.11884 8.16669 8.71233 7.92085 9.14992 7.48327C9.5875 7.04568 9.83333 6.45219 9.83333 5.83335C9.83333 5.52393 9.95625 5.22719 10.175 5.0084C10.3938 4.7896 10.6906 4.66669 11 4.66669H18C18.3094 4.66669 18.6062 4.7896 18.825 5.0084C19.0437 5.22719 19.1667 5.52393 19.1667 5.83335C19.1667 6.45219 19.4125 7.04568 19.8501 7.48327C20.2877 7.92085 20.8812 8.16669 21.5 8.16669H22.6667C23.2855 8.16669 23.879 8.41252 24.3166 8.8501C24.7542 9.28769 25 9.88118 25 10.5V21C25 21.6189 24.7542 22.2123 24.3166 22.6499C23.879 23.0875 23.2855 23.3334 22.6667 23.3334H6.33333C5.71449 23.3334 5.121 23.0875 4.68342 22.6499C4.24583 22.2123 4 21.6189 4 21V10.5C4 9.88118 4.24583 9.28769 4.68342 8.8501C5.121 8.41252 5.71449 8.16669 6.33333 8.16669"
        stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M14.5 18.6667C16.433 18.6667 18 17.0997 18 15.1667C18 13.2337 16.433 11.6667 14.5 11.6667C12.567 11.6667 11 13.2337 11 15.1667C11 17.0997 12.567 18.6667 14.5 18.6667Z"
        stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SvgPhoto;