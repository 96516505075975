import React, { useContext, useEffect, useState } from 'react';
import { Modal } from "../Modal";
import arrow from "../../assets/svg/login-back-arrow.svg";
import { useTranslation } from 'react-i18next';
import plus from '../../assets/svg/photo-add-orange.svg'
import SVGTrash from '../../assets/svg-functions/SVGTrash';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ExpenseContext from "../../context/ExpenseContext";
import { IExpenseElementReceipts } from '../../types/modelTypes';

type TProps = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  id: number
}

const ExpensesReadOnlyImageModal = ({ setShowModal, showModal, id }: TProps) => {
  const { t } = useTranslation();
  const [ currentItemIndex, setCurrentItemIndex ] = useState(1);
  const { currentExpense } = useContext(ExpenseContext);
  const [ previews, setPreviews ] = useState<Array<IExpenseElementReceipts>>([]);

  const handleClose = () => {
    setShowModal(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const current: Array<IExpenseElementReceipts> = (currentExpense && currentExpense.expense_items?.filter(item => item.id === id)[0].receipts) || [];
      setPreviews(current)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [currentExpense, id])

  return (
    <Modal isShowModal={ showModal }>
      <div className="modal-root__content">
        <div className="expenses-modal__back-header">
          <div className="button expenses-modal__back-button" onClick={ handleClose }>
            <img className="login__back-button-img" src={ arrow } alt="arrow back"/>
            { t('back') }
          </div>
          <p
            className="expenses-modal__back-text">{ currentItemIndex } of { previews && previews.length }</p>
        </div>

        <div className={ 'expenses-modal__gallery' }>
          { previews && previews.length ? (
            <Carousel emulateTouch showArrows={ false } showThumbs={ false } showStatus={ false } showIndicators={false}
                      preventMovementUntilSwipeScrollTolerance
                      selectedItem={currentItemIndex - 1}
                      onChange={ (event) => {
                        setCurrentItemIndex(event + 1)
                      } }
            >
              {
                previews.map(item => {
                  return (
                    <div className={ 'expenses-modal__gallery_item' } key={ item.id }>
                      <img src={ item.receipt } alt="preview"/>
                    </div>
                  )
                })
              }
            </Carousel>
          ) : null }
        </div>

        <div className={ 'expenses-modal__footer' }>
          <label htmlFor={ `expenses-modal-file-input-${ id }` }>
            <img src={ plus } alt="add file"/>
            <input id={ `expenses-modal-file-input-${ id }` } disabled type="file" name={ 'receipts' }
                   className={ 'display-none' } accept="image/*"/>
          </label>

          <SVGTrash />
        </div>

      </div>
    </Modal>
  );
};

export default ExpensesReadOnlyImageModal;