import React, { useEffect } from 'react';
import { InputLabel } from "./CustomInput";
import DatePicker from "react-date-picker";
import dateIcon from "../../assets/svg/input-date-icon.svg";
import { useTranslation } from "react-i18next";
import { localStorageNames } from "../../resources/localStorageNames";

type IProps = {
  name: string
  isError: boolean,
  isFormDisabled: boolean,
  value: Date | null,
  handleDateChange: any,
  defaultValue: Date | Date[] | undefined,
  required?: boolean,
  showHelperError?: boolean,
  isEditedByAdmin?: boolean,
  previousValue?: string | Date
}

const CustomDatePicker = ({
                            isError,
                            isFormDisabled,
                            value,
                            handleDateChange,
                            defaultValue,
                            name,
                            required,
                            showHelperError = false,
                            isEditedByAdmin = false,
                            previousValue = ''
                          }: IProps) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem(localStorageNames.lang)

  const isReallyEditedByAdmin = isEditedByAdmin && (new Date(previousValue).getTime() != value?.getTime());  // eslint-disable-line eqeqeq

  useEffect(() => {
    const dashes = document.getElementsByClassName('react-date-picker__inputGroup__divider')

    function changeOpacityOfDash(opacity: number) {
      for (let i = 0; i < dashes.length; i++) {
        // @ts-ignore
        dashes[i].style.opacity = opacity;
      }
    }

    if (value) {
      changeOpacityOfDash(1)
    } else {
      changeOpacityOfDash(0)
    }
  }, [ value, lang ])

  const prevDate = new Date(previousValue);
  const mm = prevDate.getMonth() + 1;
  const dd = prevDate.getDate();
  const previousDateString = `${ mm > 9 ? '' : '0' }${ mm }-${ dd > 9 ? '' : '0' }${ dd }-${ prevDate.getFullYear() }` // generate 01-01-2022

  return (
    <>
      <InputLabel className={ 'pt-18px' } text={ t('date') } required/>
      <div
        className={ `input-field__item-container input-field__item-container_date ${ isError ? 'input-field__item-container-error' : null } ${ isReallyEditedByAdmin ? 'input-field__item-container_edited' : '' }` }>
        {
          isReallyEditedByAdmin ? (
            <div className={ 'all-center' }>
              <p className={ 'input-field__item_old-value whitespace-nowrap' }>{ previousDateString }</p>
            </div>
          ) : null
        }
        <DatePicker
          name={ name }
          value={ value }
          onChange={ handleDateChange }
          disabled={ isFormDisabled }
          clearIcon={ null }
          locale={ localStorage.getItem(localStorageNames.lang) === 'ru-RU' ? 'ru-RU' : 'en-GB' }
          calendarIcon={ <img src={ dateIcon } alt="date"/> }
          format={ 'MM-dd-y' }
          defaultValue={ defaultValue }
          monthPlaceholder={ lang === 'ru-RU' ? 'Выберите' : 'Select' }
          dayPlaceholder={ lang === 'ru-RU' ? 'дату' : 'a' }
          yearPlaceholder={ lang === 'ru-RU' ? '' : 'date' }
          className={ 'w-full custom-date-picker ' }
        />
      </div>

      { showHelperError && !value && <p className="form-error form-error_padding_small">
        { t('pleaseEnterTheDateShown') }{ ' ' }
        <span className="form-error form-error_padding_small font-bold">{ t('onTheReceipt') }</span>
      </p> }
    </>
  );
};

export default CustomDatePicker;
