import React, { useContext, useEffect, useState } from 'react';
import CustomInput, { InputLabel } from "../forms/CustomInput";
import CustomTextArea from "../forms/CustomTextArea";
import { useTranslation } from "react-i18next";
import SvgPhoto from "../../assets/svg-functions/SVGPhoto";
import ExpensesImageModal from './ExpensesImageModal';
import ExpenseContext from "../../context/ExpenseContext";
import SvgAddExpenseBlockIcon from '../../assets/svg-functions/SVGAddExpenseBlockIcon';
import { useFormikContext } from "formik";
import { IExpensesInitialValues } from "../../pages/expenses";
import { CommaToDot } from '../../helpers/commaToDot';

interface TProps {
  showAddButton: boolean,
  disabled?: boolean,
  onClick: () => void,
  id: string,
  valuesIndex: number
}

const ExpensesOtherInfo = ({ showAddButton = false, disabled, onClick, id, valuesIndex }: TProps) => {
  const { t } = useTranslation();

  const { setFieldValue, values, errors, touched } = useFormikContext<IExpensesInitialValues>();
  const [ showModal, setShowModal ] = useState(false);
  const { isHaveId, setTotal, isFormDisabled, currentExpense } = useContext(ExpenseContext);

  useEffect(() => {
    const countedTotal = values.expense_items.reduce((acc, exp) => acc + (exp?.admin_version?.amount ? +exp?.admin_version?.amount || 0 : exp?.amount ? +exp.amount || 0 : 0), 0)
    if (setTotal) {
      if (countedTotal > 0) {
        setTotal(countedTotal)
      } else {
        setTotal(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ values.expense_items, values.expense_items.reduce((acc, exp) => acc + (exp?.amount ? +exp.amount || 0 : 0), 0) ])


  return (
    <>
      <CustomInput
        required
        name={ 'amount' }
        type="text"
        inputMode={ 'decimal' }
        placeholder={ t(`$ 0.00`) }
        label={ `${ t('amount') }` }
        className={ 'pt-18px' }
        value={ isFormDisabled ? values.expense_items[valuesIndex]?.admin_version.amount : values.expense_items[valuesIndex]?.amount }
        // @ts-ignore
        isError={ errors?.expense_items && touched?.expense_items && errors.expense_items[valuesIndex]?.amount && touched.expense_items[valuesIndex]?.amount }
        // @ts-ignore
        errorText={ errors.expense_items && errors.expense_items[valuesIndex]?.amount }
        isEditedByAdmin={ isFormDisabled && values?.expense_items[valuesIndex].amount !== currentExpense?.expense_items[valuesIndex].admin_version.amount }
        previousValue={ isFormDisabled ? currentExpense?.expense_items[valuesIndex]?.amount : '' }
        onChange={ (event) => {
          const target = event.target as HTMLInputElement;
          const finalValue = CommaToDot(target.value)
          setFieldValue(`expense_items.${ valuesIndex }.amount`, finalValue);
        } }
      />

      <CustomTextArea
        name={ "description" }
        rows={ 3 }
        placeholder={ t(`writeTheNameOfTheExpense`) }
        label={ `${ t('description') }` }
        labelHelperText={ ` (${t('maxTextareaLength')})` }
        className={ 'pt-18px' }
        maxLength={ 100 }
        disabled={ isFormDisabled }
        value={ isFormDisabled ? values.expense_items[valuesIndex]?.admin_version.description : values.expense_items[valuesIndex]?.description }
        // @ts-ignore
        // isError={ errors?.expense_items && touched?.expense_items && errors?.expense_items[valuesIndex]?.description && touched?.expense_items[valuesIndex]?.description }
        // @ts-ignore
        // errorText={ errors?.expense_items && errors?.expense_items[valuesIndex]?.description }
        isEditedByAdmin={ isFormDisabled && values?.expense_items[valuesIndex].description !== currentExpense?.expense_items[valuesIndex].admin_version.description }
        previousValue={ isFormDisabled ? currentExpense?.expense_items[valuesIndex].description : '' }
        onChange={ (event) => {
          const target = event.target as HTMLTextAreaElement;
          setFieldValue(`expense_items.${ valuesIndex }.description`, target.value);
        } }
      />

      <InputLabel className={ 'pt-18px' } text={ t('scanTheReceipt') } required/>
      <label htmlFor={ `expense-image-${ id  }` }>
        {
          isHaveId && values.expense_items[valuesIndex].id && values.expense_items[valuesIndex]?.receipts?.length ? (
            <div className="scan-receipt_filled" onClick={ () => {
              setShowModal(true);
            } }>
              <SvgPhoto color={ '#FFFFFF' } className={ 'scan-receipt_filled-image' }/>
              {/*// @ts-ignore*/ }
              { values.expense_items?.filter((item, index) => valuesIndex === index)[0]?.receipts?.length }&nbsp;
              {/*// @ts-ignore*/ }
              { values.expense_items?.filter((item, index) => valuesIndex === index)[0]?.receipts?.length > 1 ? t('ScansWereAdded') : t('ScanWasAdded') }
            </div>
          ) : (
            <>
              {
                values.expense_items?.filter(item => item.uuid === id)[0]?.receipts && values.expense_items?.filter(item => item.uuid === id)[0]?.receipts?.length ? (
                  <div className="scan-receipt_filled" onClick={ () => {
                    setShowModal(true);
                  } }>
                    <SvgPhoto color={ '#FFFFFF' } className={ 'scan-receipt_filled-image' }/>
                    { values.expense_items?.filter(item => item.uuid === id)[0].receipts.length }&nbsp;
                    { values.expense_items?.filter(item => item.uuid === id)[0].receipts.length > 1 ? t('ScansWereAdded') : t('ScanWasAdded') }
                  </div>
                ) : (
                  // @ts-ignore
                  <button type="button" onClick={ () => { setShowModal(true) } } className={ `scan-receipt-field ${errors?.expense_items && touched?.expense_items && errors?.expense_items[valuesIndex]?.receipts && touched?.expense_items[valuesIndex]?.receipts && 'scan-receipt-field_error'}` }>
                    {/*<input id={ `expense-image-${ id }` } className={ 'display-none' } name={ 'receipts' } multiple*/}
                    {/*       onChange={ (e) => {*/}
                    {/*         addExpenseImages(e, id, setFieldValue, valuesIndex, values, isHaveId, setIsImageLoading, showSuccessToast)*/}
                    {/*       } } type="file" accept="image/*"/>*/}
                    <SvgPhoto color={ '#FB8D57' }/>
                  </button>
                )
              }
            </>
          )
        }
        {/*// @ts-ignore*/ }
        {/*{ errors?.expense_items && touched?.expense_items && errors?.expense_items[valuesIndex]?.receipts && touched?.expense_items[valuesIndex]?.receipts && <p className="form-error form-error_padding_small">{ errors?.expense_items[valuesIndex]?.receipts }</p> }*/}
      </label>

      { showModal ? (
        <ExpensesImageModal id={ id } setShowModal={ setShowModal } valuesIndex={ valuesIndex }
                            showModal={ showModal }/>
      ) : null }

      { showAddButton ? (
        <button disabled={ disabled } type={ 'button' }
                className={ `expenses-other-info__button mt-6 ${ disabled ? null : 'expenses-other-info__button_active' }` }
                onClick={ onClick }>
          <SvgAddExpenseBlockIcon color={ disabled ? '#A0ABB6' : '#FB8D57' }/>
        </button>
      ) : null }
    </>
  );
}

export default ExpensesOtherInfo;
