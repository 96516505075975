export const positions = {
	office: {
		id: 5,
		name: "Офис",
	},
	other: {
		id: "id",
		name: "name",
	},
};
