import { ExpensesActionTypes, setExpensesValuesActionType } from "./expenses.types";
import { IExpensesInitialValues } from "../../pages/expenses";

export const setExpensesValues = (values: IExpensesInitialValues): setExpensesValuesActionType => {
  return {
    type: ExpensesActionTypes.setExpenseValues,
    payload: values
  };
};

export const resetExpensesValues = () => {
  return {
    type: ExpensesActionTypes.resetValues
  };
};