import React from 'react';
import eng from '../assets/images/eng-lang-icon.png';
import rus from '../assets/images/rus-lang-icon.png';
import exit from '../assets/svg/exit.svg';
import { useTranslation } from "react-i18next";
import { languages } from "../resources/langs";
import useLang from '../hooks/useLang';
import { customAlert, customAlertProps } from "../helpers/modals/customAlert";
import i18next from "i18next";
import { localStorageNames } from "../resources/localStorageNames";
import { useHistory } from "react-router-dom";
import URLS from '../resources/URLS';
import { setLoginStep } from "../redux/Login/login.actions";
import { LoginSteps } from "../resources/loginSteps";
import { useDispatch } from "react-redux";
import { resetWagesValues } from "../redux/Wages/wages.actions";
import { resetExpensesValues } from "../redux/Expenses/expenses.actions";

const PageTitle = ({ title = '', className = '' }) => {
  const { i18n, t } = useTranslation()
  const { updateLang, lang } = useLang();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLangClick = () => {
    const language = lang === languages.english.value ? 'Russian' : 'Английский'
    const text = i18next.t(t('changeTheLanguageToRussian'), { lang: language })

    const setNewLang = () => {
      const updateLanguage = lang === languages.english.value ? languages.russian.value : languages.english.value
      updateLang(updateLanguage);
    }

    const alertData: customAlertProps = {
      title: t('languageChange'),
      text,
      showConfirmButton: true,
      onConfirmClick: setNewLang,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      showCancelButton: true,
    }
    customAlert(alertData)
  }

  const handleExitClick = () => {
    const logout = () => {
      localStorage.removeItem(localStorageNames.token)
      localStorage.removeItem(localStorageNames.staffPosition)
      history.push(URLS.login);
      dispatch(setLoginStep(LoginSteps.stepPhoneInput))

      // CLEAR FILLED FORM VALUES
      dispatch(resetWagesValues());
      dispatch(resetExpensesValues());
    }

    const alertData: customAlertProps = {
      title: `${ t('exit') }?`,
      text: `${ t("doYouWantToExit") }?`,
      showConfirmButton: true,
      onConfirmClick: logout,
      confirmButtonText: t('yes'),
      cancelButtonText: t('no'),
      showCancelButton: true,
    }

    customAlert(alertData)
  }

  return (
    <div className={ `${ className } page-title` }>
      <h1 className={ 'page-title__text' }>{ title }</h1>
      <div className={ 'page-title__images' }>
        <img onClick={ handleLangClick } src={ i18n.language === languages.english.value ? eng : rus } alt="lang icon"
             className={ 'page-title__image_small' }/>
        <img onClick={ handleExitClick } src={ exit } alt="exit" className={ 'page-title__image' }/>
      </div>
    </div>
  );
};

export default PageTitle;