import { customAlert, customAlertProps } from "./customAlert";
import { TFunction } from "react-i18next";

const SomethingWentWrongModal = (t: TFunction<"translation">) => {
  const alertData: customAlertProps = {
    title: t('somethingWentWrong'),
    text: t('theRecordWasNotAdded'),
    icon: 'error',
    showCancelButton: true,
    cancelButtonText: 'Ok'
  }
  customAlert(alertData);
}

export default SomethingWentWrongModal;
