import { IWagesInitialValues } from "../../pages/wages";
import { BaseAction } from "../types";

export enum WagesActionTypes {
  setValues = 'setValues',
  resetValues = 'resetValues'
}

export type TWagesInitialState = {
  values: IWagesInitialValues
}

export type setWagesValuesActionType = BaseAction<WagesActionTypes, IWagesInitialValues>

export type resetWagesValuesActionType = BaseAction<WagesActionTypes, IWagesInitialValues>