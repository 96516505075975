import React from "react";
import { commonAPI } from "../API";
import Compressor from 'compressorjs';

export async function addExpenseImages(
  event: React.ChangeEvent<HTMLInputElement>,
  currentId: string,
  setFieldValue: any,
  valuesIndex: number,
  values: any,
  isHaveId: boolean | undefined,
  setIsImageLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  showSuccessToast?: (isSuccess: boolean, isMoreThanOneImage?: boolean) => void,
  setCurrentItemIndex?: React.Dispatch<React.SetStateAction<number>>
) {
  const target = event.target as HTMLInputElement;

  const filesList = Array.from(target.files || [])

  // ALLOW MAXIMUM 5 IMAGES PER EXPENSE
  // IF CHOOSEN MORE THAN 5 SHOW ERROR AND STOP
  if (filesList.length + values.expense_items[valuesIndex].receipts.length > 5) {
    showSuccessToast && showSuccessToast(false);
    return;
  }

  if (filesList && filesList.length) {
    let ImagePromises: Array<any> = [];

    const GenerateImagePromises = (currentFiles: Array<File>) => {
      const stepPromises = currentFiles.map((file: File) => {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          new Compressor(file, {
            quality: 0.6,
            maxHeight: 1920,
            maxWidth: 1920,

            async success(result) {
              const formData = new FormData();

              // @ts-ignore
              formData.append('file', result, result.name);

              await commonAPI.uploadTempMedia(formData).then((res) => {
                resolve(res.data)
              }).catch(err => {
                console.log(err?.response)
                // SomethingWentWrongModal(t)
                reject(err)
              })
            },

            error(err) {
              reject(err)
              console.log(err.message);
            },
          });
        })
      })
      ImagePromises = [ ...ImagePromises, ...stepPromises ]
    }

    GenerateImagePromises(filesList)

    setIsImageLoading && setIsImageLoading(true)
    try {
      await Promise.all(ImagePromises).then((res) => {
        let createdReceiptsFromServer = []
        if (isHaveId) {
          createdReceiptsFromServer = res.map(item => {
            return {
              id: item.id,
              image: item.file,
              action: 'add'
            }
          })
        } else {
          createdReceiptsFromServer = res.map(item => {
            return {
              image: item.file
            }
          })
        }
        const prevFormikValuesReceipts = [ ...values.expense_items[valuesIndex].receipts ]
        setFieldValue(`expense_items.${ valuesIndex }.receipts`, [ ...prevFormikValuesReceipts, ...createdReceiptsFromServer ]);
        showSuccessToast && showSuccessToast(true, filesList.length > 1);
        if (prevFormikValuesReceipts.length) {
          setCurrentItemIndex && setCurrentItemIndex(prevFormikValuesReceipts.length + 1)
        }
      })
    } catch (err) {
      console.log(err);
    } finally {
      setIsImageLoading && setIsImageLoading(false)
    }
  }

  // fix problem with same file inputing again
  target.value = '';
}
