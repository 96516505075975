import { TLoginStepItem } from '../../resources/loginSteps';
import {
  LoginActionTypes,
  setPhoneAction,
  LoginPhonePayload,
  PhoneWithDashPayload,
  setPhoneWithDashAction,
  setLoginStepActionType,
  TimerPayload,
  setTimerActionType
} from './login.types';

export const setUserPhone = (phone: LoginPhonePayload): setPhoneAction => {
  return {
    type: LoginActionTypes.setPhone,
    payload: phone
  };
};

export const setPhoneWithDash = (phone: PhoneWithDashPayload): setPhoneWithDashAction => {
  return {
    type: LoginActionTypes.setPhoneWithDash,
    payload: phone
  };
};

export const setLoginStep = (step: TLoginStepItem): setLoginStepActionType => {
  return {
    type: LoginActionTypes.setLoginStep,
    payload: step
  };
};

export const setLoginTimer = (timer: TimerPayload): setTimerActionType => {
  return {
    type: LoginActionTypes.setTimer,
    payload: timer
  };
};

export const resetLoginData = () => {
  return {
    type: LoginActionTypes.setLoginStep,
  };
};