import { useEffect } from 'react';
import { localStorageNames } from "../resources/localStorageNames";
import { useHistory } from 'react-router-dom';
import URLS from "../resources/URLS";
import { useTranslation } from "react-i18next";

const UseLang = () => {
  const history = useHistory();
  const { i18n } = useTranslation()
  const lang = localStorage.getItem(localStorageNames.lang)

  useEffect(() => {
    const lang = localStorage.getItem(localStorageNames.lang);

    if (!lang) {
      history.push(URLS.login)
    }
  }, [history])

  function updateLang(lang: string) {
    localStorage.setItem(localStorageNames.lang, lang)
    localStorage.setItem("i18nextLng", lang)
    i18n.changeLanguage(lang).then(() => {})
  }

  return { lang, updateLang }
};

export default UseLang;