import React from 'react';

const SvgAddExpenseBlockIcon = ({ color = '#A0ABB6' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="5" fill={ color }/>
      <path d="M12.5 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.5 12H19.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default SvgAddExpenseBlockIcon;