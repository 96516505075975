import React, { useContext, useState } from 'react';
import CustomInput, { InputLabel } from "../forms/CustomInput";
import CustomTextArea from "../forms/CustomTextArea";
import { useTranslation } from "react-i18next";
import SvgPhoto from "../../assets/svg-functions/SVGPhoto";
import { IServerExpenseItem } from "../../types/modelTypes";
import ExpensesReadOnlyImageModal from './ExpensesReadOnlyImageModal';
import ExpenseContext from "../../context/ExpenseContext";

interface TProps {
  id: number,
  item: IServerExpenseItem
}

const ExpensesReadOnlyOtherInfo = ({ id, item }: TProps) => {
  const { t } = useTranslation();
  const [ showModal, setShowModal ] = useState(false);
  const { isFormDisabled } = useContext(ExpenseContext);

  return (
    <>
      <CustomInput
        name={ 'amount' }
        type="text"
        inputMode={ 'decimal' }
        placeholder={ '' }
        label={ `${ t('amount') }` }
        className={ 'pt-18px' }
        value={ item.status === '_added' ? item.amount : '' }
        isEditedByAdmin={ item.status !== '_added' }
        previousValue={ item.status === '_added' ? '' : item.amount }
        isShowEditedLabel={ false }
      />

      <CustomTextArea
        name={ "description" }
        rows={ 2 }
        placeholder={ '' }
        label={ `${ t('description') }` }
        labelHelperText={ ` (${ t('maxTextareaLength') })` }
        className={ 'pt-18px' }
        maxLength={ 100 }
        disabled={ isFormDisabled }
        value={ item.status === '_added' ? item.description : '' }
        isEditedByAdmin={ item.status !== '_added' }
        previousValue={ item.status === '_added' ? '' : item.description }
        isShowEditedLabel={ false }
      />

      <InputLabel className={ 'pt-18px' } text={ t('scanTheReceipt') }/>
      <label htmlFor={ `expense-image-${ id }` }>
        <div className={ `scan-receipt_filled scan-receipt_filled_disabled` } onClick={ () => {
          if (item.receipts.length) {
            setShowModal(true);
          }
        } }>
          <SvgPhoto color={ '#FFFFFF' } className={ 'scan-receipt_filled-image' }/>
          { item.receipts.length }&nbsp;
          { item.receipts.length > 1 ? t('ScansWereAdded') : t('ScanWasAdded') }
        </div>
      </label>

      { showModal ? (
        <ExpensesReadOnlyImageModal setShowModal={ setShowModal } showModal={ showModal } id={ item.id || 0 }/>
      ) : null }
    </>
  );
}

export default ExpensesReadOnlyOtherInfo;