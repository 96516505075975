import React, { useContext, useEffect, useState } from 'react';
import InfoContainer from "../info-blocks/InfoContainer";
import { useTranslation } from "react-i18next";
import CustomInput, { InputLabel } from "../forms/CustomInput";
import { useFormikContext } from "formik";
import useCommonApiData, { TJobsPositionsListItem } from "../../hooks/useCommonApiData";
import { defaultExpenseImageId, IExpensePageParams, IExpensesInitialValues } from "../../pages/expenses";
import ExpensesOtherInfo from './ExpensesOtherInfo';
import ExpenseContext from "../../context/ExpenseContext";
import CustomDatePicker from "../forms/CustomDatePicker";
import { useDispatch } from 'react-redux';
import { setExpensesValues } from "../../redux/Expenses/expenses.actions";
import { useParams } from "react-router-dom";
import { checkIsOfficePosition } from '../../helpers/staffPosition-helpers';

const ExpensesMain = () => {
  const { t } = useTranslation();
  const { setFieldValue, values, errors, touched } = useFormikContext<IExpensesInitialValues>();
  const { expensesTypes } = useCommonApiData();
  const dispatch = useDispatch();
  const [ showJobField, setShowJobField ] = useState(true);
  const { isHaveId, isReviewed, handleAddExpenseClick, isFormDisabled, currentExpense, isEditedByAdmin } = useContext(ExpenseContext);
  const params: IExpensePageParams = useParams();
  const isOfficePosition = checkIsOfficePosition();

  const handleDateChange = (date: Date | null) => {
    setFieldValue('date', date)
  }

  const handleOtherInfoClick = () => {
    if (handleAddExpenseClick) {
      handleAddExpenseClick(setFieldValue, values)
    }
  }

  useEffect(() => {
    if (values.expense_type === expensesTypes[0].id && !isOfficePosition) {
      setShowJobField(true)
    } else {
      setShowJobField(false);

      if (isHaveId) return;

      setFieldValue('job_number', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ values.expense_type ])

  useEffect(() => {
    if (!params.expenseId) {
      dispatch(setExpensesValues(values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, dispatch])

  return (
    <InfoContainer showStatus={ isHaveId } isReviewed={ isReviewed } isEditedByAdmin={isEditedByAdmin}
                   title={ t('expenseInformation') } required>
      <CustomDatePicker
        name={ 'date' }
        isFormDisabled={ isFormDisabled || false }
        isError={ !!(errors.date && touched.date) }
        handleDateChange={ handleDateChange }
        value={ values?.date || null }
        defaultValue={ values?.date }
        showHelperError={ true }
        isEditedByAdmin={ isFormDisabled && values.date !== currentExpense?.admin_version.date }
        previousValue={ currentExpense?.date }
      />
      { !isOfficePosition ? (
        <>
          <InputLabel className={ 'pt-18px' } text={ t('expenseType') } required/>
          <div className={ 'wages__position' }>
            { expensesTypes.map((item: TJobsPositionsListItem) => {
                const currentLabel = item.id === values.expense_type ? 'wages__position-item_active' : '';
                const isOldValueLabel = values.expense_type !== currentExpense?.expense_type.id && isFormDisabled ?
                  item.id === currentExpense?.expense_type.id ? 'wages__position-item_old' : '' : ''
                const labelClassName = currentLabel + isOldValueLabel;

                return (
                  <label htmlFor={ item.id.toString() } key={ item.id } onClick={ () => {
                    if (isFormDisabled) return;

                    setFieldValue('expense_type', item.id)
                  } }
                        className={ `wages__position-item wages__position-item_padding_sm ${ labelClassName }` }>
                    <input checked={ item.id === values.expense_type } name={ 'expense_type' } type="radio" value={ item.id }
                          className={ 'display-none' }/>
                    <img src={ item.icon } alt={ item.name }/>
                    { item.name }
                  </label>
                )
              }
            ) }
          </div>
        </>
      ) : null }

      { showJobField ? (
        <CustomInput
          name={'job_number'}
          required
          type="text"
          inputMode="numeric"
          maxLength={ 255 }
          label={ `${ t('job') }` }
          className={ 'pt-18px' }
          value={ isFormDisabled ? currentExpense?.admin_version.job_number : values.job_number }
          isError={ errors.job_number && touched.job_number }
          errorText={ errors.job_number }
          isEditedByAdmin={ isFormDisabled && values.job_number !== currentExpense?.admin_version.job_number }
          previousValue={ currentExpense?.job_number }
          onChange={ (e) => {
            setFieldValue('job_number', e.target.value)
          } }
        />
      ) : null }

      <ExpensesOtherInfo id={ defaultExpenseImageId }
                         showAddButton={ !isFormDisabled && values.expense_items.length === 1 }
                         disabled={ !(values.expense_items[0].amount && values.expense_items[0].description && values.date && values.expense_items[0].receipts.length) }
                         onClick={ handleOtherInfoClick } valuesIndex={ 0 }/>
    </InfoContainer>
  );
};

export default ExpensesMain;
