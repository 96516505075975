import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru.json';
import en from './locales/en.json';
import { localStorageNames } from "../resources/localStorageNames";
import Backend from 'i18next-http-backend';

const storedLang = localStorage.getItem(localStorageNames.lang);

const options = {
  resources: {
    "ru-RU" : {
      common: ru
    },
    "en-US":{
      common: en
    }
  },
  lng: storedLang || 'en-US',
  fallbackLng: storedLang || 'en-US',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
  storedLang
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init(options);

export default i18n;