import React from 'react';
import BottomNavbar from "./BottomNavbar";

type TMainLayoutProps = {
  children: React.ReactNode,
  className: string,
  showTotal?: boolean,
  total?: number,
}

const MainLayout: React.FC<TMainLayoutProps> = ({
                                                  className = '',
                                                  showTotal = false,
                                                  total = 0.00,
                                                  children
                                                }) => {

  return (
    <div className="main-layout-container">
      <div className={ `main-layout ${ className }` }>
        { children }

        <BottomNavbar showTotal={ showTotal } total={ total }/>
      </div>
    </div>
  );
};

export default MainLayout;