import React, { ChangeEventHandler } from 'react';

interface IProps {
  label?: string,
  labelHelperText?: string
  placeholder?: string;
  id?: string,
  name: string,
  size?: string,
  maxLength?: number,
  onChange?: ChangeEventHandler<HTMLTextAreaElement> | undefined,
  defaultValue?: string | number | readonly string[] | undefined,
  value?: string | number | readonly string[] | undefined,
  className?: string,
  errorText?: string,
  isError?: boolean | string,
  disabled?: boolean,
  autoFocus?: boolean,
  readOnly?: boolean,
  rows?: number,
  cols?: number,
  isEditedByAdmin?: boolean,
  isShowEditedLabel?: boolean,
  previousValue?: string | number
}

const CustomTextArea: React.FC<IProps> = ({
                                            label = '',
                                            labelHelperText = '',
                                            placeholder = '',
                                            id = '',
                                            name = '',
                                            size = 'sm',
                                            maxLength,
                                            onChange = undefined,
                                            defaultValue = '',
                                            value = undefined,
                                            className = '',
                                            errorText = '',
                                            isError= false,
                                            disabled = false,
                                            autoFocus = false,
                                            readOnly = false,
                                            rows,
                                            cols,
                                            isEditedByAdmin = false,
                                            isShowEditedLabel= true,
                                            previousValue = ''
                                          }) => {
  // @ts-ignore
  const isMaxValue = maxLength && value && value?.length > maxLength - 3 && !disabled;

  // eslint-disable-next-line eqeqeq
  const isReallyEditedByAdmin = isEditedByAdmin && previousValue != value;

  return (
    <div
      className={ `input-field ${ className } ${ size && `input-field--size-${ size }` } ` }>
      <label htmlFor={ id } className="input-field__label">
        { label }
        {labelHelperText ? (<span className={'input-field__label-helper'}>{labelHelperText}</span>): null}
      </label>
      <div className={ `input-field__item-container flex-col ${ (isError || isMaxValue) && 'input-field__item-container-error' } ${isReallyEditedByAdmin ? 'input-field__item-container_edited' : ''} ` }>
        { isReallyEditedByAdmin? (
          <p className="input-field__item_old-value">
            { previousValue }
          </p>
        ) : null }
        <textarea
          id={ id }
          value={ value }
          defaultValue={ defaultValue }
          maxLength={ maxLength }
          name={ name }
          autoFocus={ autoFocus }
          placeholder={ placeholder }
          onChange={ onChange }
          disabled={ disabled }
          className={ `input-field__item ${ isError && 'input-field__item-error' }` }
          readOnly={ readOnly }
          rows={rows}
          cols={ cols }
        />
      </div>
      {/*{ isError && <span className="form-error form-error_padding_small">{ errorText }</span> }*/}
      {/*{ isEditedByAdmin && isShowEditedLabel ? <EditedByAdmin/> : null }*/}
    </div>
  );
};

export default CustomTextArea;