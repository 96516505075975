import { TWagesInitialState, WagesActionTypes } from "./wages.types";
import { AnyAction } from 'redux'

const INITIAL_STATE: TWagesInitialState = {
  values: {
    job_number: "",
    date: undefined,
    job_location: 1,
    position: undefined,
    minutes: undefined,
    rate_per_hour: undefined,
    bulky_item_fee_amount: undefined,
    material_comm_amount: undefined,
    drove_miles: undefined,
    rate_per_mile: undefined,
    tips_amount: undefined,
    employee_notes: "",
    additional_amount: undefined,
    additional_amount_description: ""
  }
};

const WagesReducer = (state: TWagesInitialState = INITIAL_STATE, action: AnyAction): TWagesInitialState => {
  switch (action.type) {
    case WagesActionTypes.setValues:
      return {
        ...state,
        values: action.payload
      };

    case WagesActionTypes.resetValues:
      return {
        ...state,
        values: {
          job_number: "",
          date: undefined,
          job_location: 1,
          position: undefined,
          minutes: undefined,
          rate_per_hour: undefined,
          bulky_item_fee_amount: undefined,
          material_comm_amount: undefined,
          drove_miles: undefined,
          rate_per_mile: undefined,
          tips_amount: undefined,
          employee_notes: "",
          additional_amount: undefined,
          additional_amount_description: ""
        }
      };

    default:
      return state;
  }
};

export default WagesReducer;