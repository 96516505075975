import React from 'react';
import { useTranslation } from "react-i18next";

const EditedByAdmin = ({ text = '', className = 'mt-1'}) => {
  const { t } = useTranslation();

  return (
    <p className={`text__edited-by-admin ${className}`}>
      { text ? text : t('editedByAdmin') }
    </p>
  );
};

export default EditedByAdmin;