import { setWagesValuesActionType, WagesActionTypes } from "./wages.types";
import { IWagesInitialValues } from "../../pages/wages";

export const setWagesValues = (values: IWagesInitialValues): setWagesValuesActionType => {
  return {
    type: WagesActionTypes.setValues,
    payload: values
  };
};

export const resetWagesValues = () => {
  return {
    type: WagesActionTypes.resetValues
  };
};