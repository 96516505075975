import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";

export interface customAlertProps {
  title?: string,
  text?: string,
  icon?: SweetAlertIcon | undefined,
  showConfirmButton?: boolean,
  onConfirmClick?: () => void,
  confirmButtonText?: string,
  cancelButtonText?: string,
  showCancelButton?: boolean,
  confirmButtonClass?: string,
  cancelButtonClass?: string,
  updateFocus?: boolean,
  textClassName?: string
}

export function customAlert(data: customAlertProps) {
  const {
    title,
    text,
    icon,
    showConfirmButton,
    onConfirmClick,
    confirmButtonText,
    cancelButtonText,
    showCancelButton,
    confirmButtonClass,
    cancelButtonClass,
    updateFocus = true,
    textClassName = ''
  } = data

  let options: SweetAlertOptions = {
    title,
    text,
    cancelButtonText: cancelButtonText || "Ok",
    showCancelButton: showCancelButton,
    showConfirmButton: showConfirmButton || false,
    focusConfirm: false,
    // reverseButtons: true,
    customClass: {
      container: 'custom-swal__container',
      popup: '',
      title: 'custom-swal__title',
      closeButton: '',
      icon: 'custom-swal__icon',
      image: '',
      htmlContainer: `custom-swal__text ${textClassName}`,
      input: '',
      validationMessage: '',
      actions: 'custom-swal__buttons',
      confirmButton: confirmButtonClass ? confirmButtonClass : 'custom-swal__btn custom-swal__btn-confirm',
      denyButton: '',
      cancelButton: cancelButtonClass ? cancelButtonClass : 'custom-swal__btn custom-swal__btn-cancel',
      loader: '',
      footer: ''
    },
  }

  if (icon) {
    options = {
      ...options,
      icon
    }
  }

  if (showConfirmButton && confirmButtonText) {
    options = {
      ...options,
      showConfirmButton,
      confirmButtonText,
      cancelButtonText
    }
  }

  Swal.fire(options).then((result) => {
    if (result.isConfirmed && onConfirmClick) {
      onConfirmClick()
    }
  })

  // remove focus from confirm button to cancel
  if (updateFocus) {
    const confirmButton = document.getElementsByClassName('swal2-confirm')[0]
    const cancelButton = document.getElementsByClassName('swal2-cancel')[0]
    if (confirmButton && cancelButton) {
      // @ts-ignore
      confirmButton.blur();
      // @ts-ignore

      cancelButton.focus();

    }
  }
}

