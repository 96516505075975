import config from "./config";
import { localStorageNames } from "../resources/localStorageNames";
import axios from 'axios'

export const baseApiURL = config.BASE_API_URL;

const axiosInstance = axios.create({
  baseURL: baseApiURL,
  headers: {
    'content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem(localStorageNames.token);
    const lang = localStorage.getItem(localStorageNames.lang);
    if (authToken) {
      // @ts-ignore
      config.headers.Authorization = `${ authToken }`;
    }
    if (lang) {
      // @ts-ignore
      config.headers['Accept-Language'] = lang;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error)
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response.status === 401) {
      localStorage.removeItem(localStorageNames.token);
      // @ts-ignore
      window.location = '/login';
    }
    return Promise.reject(error)
  }
)

const staffApiPrefix = '/staff-api/v01/'
export const staffAPI = {
  login: {
    checkPhoneNumber: (data: any) => axiosInstance.post(`${ staffApiPrefix }auth/check-phone-number`, data),
    login: (data: any) => axiosInstance.post(`${ staffApiPrefix }auth/login`, data),
    retrieveCurrentUser: () => axiosInstance.get(`${ staffApiPrefix }auth/current-user`),
  },
  wages: {
    createNewWage: (data: any) => axiosInstance.post(`${ staffApiPrefix }wages/`, data),
    getWages: (week = 'current') => axiosInstance.get(`${ staffApiPrefix }wages/?week=${ week }`),
    retrieveSingleWage: (id: any) => axiosInstance.get(`${staffApiPrefix}wages/${id}`),
    updateMarkStatus: (id: any) => axiosInstance.post(`${ staffApiPrefix }wages/${ id }/mark-seen`),
    updateWage: (id: any, data: any) => axiosInstance.patch(`${ staffApiPrefix }wages/${ id }`, data),
  },
  expenses: {
    createNewExpense: (data: any) => axiosInstance.post(`${ staffApiPrefix }expenses/`, data),
    getExpenses: (week = 'current') => axiosInstance.get(`${ staffApiPrefix }expenses/?week=${ week }`),
    retrieveSingleExpense: (id: any) => axiosInstance.get(`${ staffApiPrefix }expenses/${ id }`),
    updateMarkStatus: (id: any) => axiosInstance.post(`${ staffApiPrefix }expenses/${ id }/mark-seen`),
    updateExpense: (id: any, data: any) => axiosInstance.patch(`${ staffApiPrefix }expenses/${ id }`, data),
  },
}

const commonApiPrefix = '/common-api/v01/'
export const commonAPI = {
  getExpenseTypes: () => axiosInstance.get(`${ commonApiPrefix }expense-types`),
  getSettings: () => axiosInstance.get(`${ commonApiPrefix }settings`),
  getJobsPositionsList: () => axiosInstance.get(`${commonApiPrefix}job-positions`),
  getJobsLocationsList: () => axiosInstance.get(`${ commonApiPrefix }job-locations`),
  uploadTempMedia: (formData: any) => axiosInstance.post(`${ commonApiPrefix }temp-media`, formData, {
    headers: {'Content-Type': 'multipart/form-data'}
  }),
}
