import { LoginSteps } from '../../resources/loginSteps';
import { LoginActionTypes, TLoginInitialState } from './login.types'
import { AnyAction } from 'redux'

const INITIAL_STATE: TLoginInitialState = {
  phone: '',
  phoneWithDash: '',
  loginStep: LoginSteps.stepPhoneInput,
  timer: 59
};

const LoginReducer = (state: TLoginInitialState = INITIAL_STATE, action: AnyAction): TLoginInitialState => {
  switch (action.type) {
    case LoginActionTypes.setPhone:
      return {
        ...state,
        phone: String(action.payload)
      };

    case LoginActionTypes.setPhoneWithDash:
      return {
        ...state,
        phoneWithDash: String(action.payload)
      };

    case LoginActionTypes.setLoginStep:
      return {
        ...state,
        loginStep: Number(action.payload)
      };

    case LoginActionTypes.setTimer:
      return {
        ...state,
        timer: Number(action.payload)
      };

    case LoginActionTypes.resetLoginData:
      return {
        ...state,
        phone: '',
        phoneWithDash: '',
        loginStep: LoginSteps.stepPhoneInput,
        timer: 59
      };

    default:
      return state;
  }
};

export default LoginReducer;